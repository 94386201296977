// src/components/SurgicalItemsSupply.js

import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import VerifiedIcon from "@mui/icons-material/Verified";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CustomizationIcon from "@mui/icons-material/BuildCircle";
import { useMediaQuery } from "react-responsive";
import "./SurgicalItemsSupply.css";
import { useNavigate } from "react-router-dom";

const SurgicalItemsSupply = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const navigate = useNavigate();
  return (
    <Container maxWidth="lg" className="surgical-items-supply">
      <Box
        textAlign="center"
        sx={{
          position: "relative",
        }}
      >
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          onClick={() => navigate("/our-services")}
          sx={{
            position: "absolute",
            top: isMobile ? "-25px" : "10px",
            left: 0,
            fontSize: "10px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Back to list
        </Typography>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          sx={{
            position: "absolute",
            top: isMobile ? "-25px" : "10px",
            right: 0,
            fontSize: "10px",
            textDecoration: "underline",
          }}
        >
          OUR SERVICES
        </Typography>
        <Typography variant="h4" gutterBottom>
          Surgical Items Supply
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box className="service-description">
            <Typography variant="body1" paragraph>
              SAR Pharma International is a trusted provider of surgical items,
              catering to the needs of healthcare professionals and institutions
              worldwide. We understand the critical importance of reliable and
              high-quality surgical supplies in delivering optimal patient care.
            </Typography>
            <Typography variant="body1" paragraph>
              Our extensive range of surgical items encompasses a diverse array
              of products, including but not limited to surgical instruments,
              disposables, wound care products, and medical equipment. We
              collaborate with reputable manufacturers and suppliers to ensure
              that our customers have access to top-notch surgical supplies that
              meet rigorous quality standards.
            </Typography>
            <Typography variant="body1" paragraph>
              We understand that different healthcare facilities have unique
              requirements, which is why we offer a wide selection of surgical
              items to cater to various specialties and procedures. From general
              surgery to orthopedics, gynecology to ophthalmology, our catalog
              is designed to meet the diverse needs of healthcare professionals.
            </Typography>
            <Typography variant="body1" paragraph>
              SAR Pharma International is dedicated to ensuring a streamlined
              and efficient supply chain. We prioritize prompt and secure
              delivery, minimizing any disruptions to your surgical operations.
              Our experienced logistics team works diligently to ensure that
              your orders are processed and shipped with the utmost care,
              adhering to strict timelines.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4} my={2}>
        <Grid item xs={12} md={6}>
          <img
            src={"/assets/images/surgical_1.jpg"}
            alt="Surgical Items Supply"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              aspectRatio: "16/9",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={"/assets/images/surgical_2.jpg"}
            alt="Surgical Items Supply"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              aspectRatio: "16/9",
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} my={4}>
        <Grid item xs={12}>
          <Paper className="service-highlights" elevation={3}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <MedicalServicesIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Extensive Product Range"
                  secondary="Our catalog includes a comprehensive selection of surgical items to meet diverse specialty and procedural requirements."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <VerifiedIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Quality Assurance"
                  secondary="We prioritize the authenticity, reliability, and safety of every surgical item we supply, adhering to stringent quality control measures."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CustomizationIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Customized Solutions"
                  secondary="We understand that each healthcare facility has unique needs. Our team works closely with you to provide tailored solutions and assist in selecting the most appropriate surgical supplies."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocalShippingIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Reliable and Timely Delivery"
                  secondary="We strive to ensure prompt and secure delivery of your surgical items, minimizing any disruptions to your operations."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Box textAlign="center" my={4}>
        <Typography variant="body1" paragraph>
          Choose SAR Pharma and experience reliable, high-quality surgical item
          supply for your healthcare facility.
        </Typography>
      </Box>
    </Container>
  );
};

export default SurgicalItemsSupply;

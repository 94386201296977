// src/components/BrandMedicineSupply.js

import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import VerifiedIcon from "@mui/icons-material/Verified";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./BrandMedicineSupply.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
const BrandMedicineSupply = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const navigate = useNavigate();
  return (
    <Container maxWidth="lg" className="brand-medicine-supply">
      <Box
        textAlign="center"
        sx={{
          position: "relative",
        }}
        // my={4}
      >
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          onClick={() => navigate("/our-services")}
          sx={{
            position: "absolute",
            top: isMobile ? "-25px" : "10px",
            left: 0,
            fontSize: "10px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Back to list
        </Typography>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          sx={{
            position: "absolute",
            top: isMobile ? "-25px" : "10px",
            right: 0,
            fontSize: "10px",
            textDecoration: "underline",
          }}
        >
          OUR SERVICES
        </Typography>
        <Typography variant="h4" gutterBottom>
          Brand Medicine Supply
        </Typography>
      </Box>
      <Grid container spacing={4} mb={4}>
        <Grid item xs={12} md={12}>
          <Box className="service-description">
            <Typography variant="body1" paragraph>
              SAR Pharma International takes pride in being a leading provider
              of generic and internationally branded medicines for patients
              worldwide. We understand the critical importance of ensuring
              access to high-quality medications, and our extensive network
              allows us to source and supply a wide range of internationally
              recognized pharmaceutical products.
            </Typography>
            <Typography variant="body1" paragraph>
              Our commitment to quality drives us to collaborate with reputable
              manufacturers and suppliers from across the globe. Through these
              partnerships, we can offer a comprehensive selection of
              internationally branded medicines, meeting the diverse needs and
              preferences of our clients and patients.
            </Typography>
            <Typography variant="body1" paragraph>
              By offering internationally branded medicines, we provide our
              customers with peace of mind regarding the authenticity, safety,
              and efficacy of the products they receive. We adhere to strict
              quality control measures throughout the supply chain, ensuring
              that every medication we distribute meets stringent international
              standards and regulations.
            </Typography>
            <Typography variant="body1" paragraph>
              SAR Pharma International understands that each patient's journey
              is unique, and their specific medical requirements may necessitate
              specific brands or formulations of medicine. Our extensive catalog
              encompasses a wide range of therapeutic categories, including but
              not limited to oncology/anti-cancer medicine, cardiovascular
              medications, neurology treatments, respiratory care, and much
              more. Whether it's for chronic conditions or acute illnesses, we
              strive to fulfill the varied medication needs of our customers.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={4} my={2}>
        <Grid item xs={12} md={6}>
          <img
            src={"/assets/images/medicine_1.png"}
            alt="Brand Medicine Supply"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              aspectRatio: "16/9",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={"/assets/images/medicine_2.jpg"}
            alt="Brand Medicine Supply"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              aspectRatio: "16/9",
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper className="service-highlights" elevation={3}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LocalPharmacyIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Extensive Product Range"
                  secondary="Our catalog includes a diverse array of internationally recognized pharmaceutical products, ensuring that you have access to a wide selection of medicines."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <VerifiedIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Authenticity and Quality"
                  secondary="We prioritize the authenticity, safety, and efficacy of every medication we supply. Through stringent quality control measures, we ensure that all our products meet the highest industry standards."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AllInclusiveIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Reliable Supply Chain"
                  secondary="With a robust global network of trusted manufacturers and suppliers, we ensure a steady and reliable supply of internationally branded medicines."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SupportAgentIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Expert Guidance"
                  secondary="Our knowledgeable and experienced team is available to provide guidance and assistance in selecting the most suitable internationally branded medicines for your specific needs."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AttachMoneyIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Competitive Pricing"
                  secondary="We understand the financial burden that can come with accessing branded medications. Therefore, we strive to offer cost-effective pricing without compromising on quality, making internationally branded medicines more accessible to patients worldwide."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Box textAlign="center" my={4}>
        <Typography variant="body1" paragraph>
          Choose SAR Pharma International as your trusted partner for
          international branded and generic medicine supply, and let us help you
          on your journey towards optimal health and well-being.
        </Typography>
      </Box>
    </Container>
  );
};

export default BrandMedicineSupply;

// import React from 'react';
// import { Container, Typography, Grid, TextField, Button, Box, Paper } from '@mui/material';
// import EmailIcon from '@mui/icons-material/Email';
// import PhoneIcon from '@mui/icons-material/Phone';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import './ContactUs.css';

// const ContactUs = () => {
//   return (
//     <Container maxWidth="lg" className="contact-us">
//       {/* <Box className="contact-us-banner">
//         <img src="/assets/images/banner.jpg" alt="Contact Us Banner" className="banner-image" />
//       </Box> */}
//       <Box className="contact-info" sx={{ py: 5, textAlign: 'center', }}>
//         <Typography variant="h4" gutterBottom>Contact Us</Typography>
//         <Grid container spacing={3} justifyContent="center">
//           <Grid item xs={12} sm={6} md={4}>
//             <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
//               <EmailIcon fontSize="large" color="primary" />
//               <Typography variant="h6">Email Us</Typography>
//               <Typography variant="body1">
//                 <a href="mailto:info@sarpharmainternational.com">info@sarpharmainternational.com</a>
//               </Typography>
//               <Typography variant="body2">
//                 Interactively grow empowered for process-centric total linkage.
//               </Typography>
//             </Paper>
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
//               <PhoneIcon fontSize="large" color="primary" />
//               <Typography variant="h6">Call Us</Typography>
//               <Typography variant="body1">
//                 <a href="tel:029-00142687">029-00142687</a>
//               </Typography>
//               <Typography variant="body2">
//                 Distinctively disseminate focused solutions clicks-and-mortar ministate.
//               </Typography>
//             </Paper>
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
//               <LocationOnIcon fontSize="large" color="primary" />
//               <Typography variant="h6">Location</Typography>
//               <Typography variant="body1">
//                 Boho One, Bridge Street West, Middlesbrough, North Yorkshire, TS2 1AE.
//               </Typography>
//               <Typography variant="body1">
//                 561-4535 Nulla LA United States 96522.
//               </Typography>
//             </Paper>
//           </Grid>
//         </Grid>
//       </Box>
//       <Box className="contact-form-section" sx={{ py: 5, textAlign: 'center' }}>
//         <Typography variant="h5" gutterBottom>For any support just send your query</Typography>
//         <Typography variant="body1" sx={{ mb: 5, maxWidth: '600px', mx: 'auto' }}>
//           Collaboratively promote client-focused convergence via customer-directed alignments via plagiarized strategic users and standardized infrastructures.
//         </Typography>
//         <Box component="form" sx={{ maxWidth: '600px', mx: 'auto' }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6}>
//               <TextField fullWidth label="Enter Your Name" name="name" required variant="outlined" />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField fullWidth label="Enter Your Email" name="email" required variant="outlined" type="email" />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField fullWidth label="Enter Your Subject" name="subject" required variant="outlined" />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField fullWidth label="Write your message here" name="message" required variant="outlined" multiline rows={4} />
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" color="primary" fullWidth>Send Message</Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default ContactUs;

import React from "react";
import { useMutation } from "@tanstack/react-query";
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  Link,
  Divider,
} from "@mui/material";
import { LocationOn, Email, SupportAgent, WhatsApp } from "@mui/icons-material";
import axios from "axios";
import { sendContactMessage } from "../../api/service";

// Assuming you have an API endpoint for form submission
const submitContactForm = async (formData) => {
  const response = await axios.post(
    "https://api.example.com/contact",
    formData
  );
  return response.data;
};

const ContactUs = () => {
  const mutation = useMutation({
    mutationFn: sendContactMessage,
    onSuccess: (data) => {
      console.log("Form submitted successfully", data);
    },
    onError: (error) => {
      console.error("Error submitting form", error);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formObject = Object.fromEntries(formData.entries());
    mutation.mutate(formObject);
  };

  return (
    <Box sx={{}}>
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
            }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ maxWidth: "600px", mx: "auto" }}
          >
            Get all our info and also can message us directly from here
          </Typography>
        </Box>

        {/* Main Content */}
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 6 },
            borderRadius: 4,
            bgcolor: "white",
            boxShadow: "0 0 20px rgba(0,0,0,0.05)",
          }}
        >
          <Grid container spacing={6}>
            {/* Contact Form */}
            <Grid item xs={12} md={6}>
              <Typography variant="h5" sx={{ mb: 4, fontWeight: 600 }}>
                Send us a Message
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  "& .MuiTextField-root": {
                    mb: 3,
                  },
                }}
              >
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  placeholder="Your full name"
                  required
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <TextField
                  fullWidth
                  name="from"
                  label="Email"
                  placeholder="demo@domain.com"
                  required
                  type="email"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  name="subject"
                  label="Subject"
                  placeholder="Email Subject"
                  required
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  name="contact"
                  label="Contact Number"
                  placeholder="01700000000"
                  required
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  name="message"
                  label="Your message"
                  multiline
                  rows={4}
                  required
                  variant="outlined"
                  sx={{ mb: 4 }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#215d66",
                    py: 1.5,
                    borderRadius: 2,
                    textTransform: "none",
                    fontSize: "1.1rem",
                    fontWeight: 600,
                    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0 6px 16px rgba(0,0,0,0.2)",
                    },
                    transition: "all 0.3s ease",
                  }}
                  disabled={mutation.isLoading}
                >
                  {mutation.isLoading ? "Sending..." : "Send Message"}
                </Button>
              </Box>
            </Grid>

            {/* Contact Information */}
            <Grid item xs={12} md={6}>
              <Typography variant="h5" sx={{ mb: 4, fontWeight: 600 }}>
                Get in Touch
              </Typography>
              <Box sx={{ pl: { md: 4 } }}>
                {[
                  {
                    icon: LocationOn,
                    title: "Our Address",
                    content: ["Mohammedpur, Dhaka, Bangladesh"],
                  },
                  // {
                  //   icon: Email,
                  //   title: "Contact Info",
                  //   content: [
                  //     "Open a chat or give us call at",
                  //     <Link
                  //       href="tel:3108415500"
                  //       sx={{
                  //         color: "#215d66",
                  //         textDecoration: "none",
                  //         fontWeight: 500,
                  //       }}
                  //     >
                  //       310.841.5500
                  //     </Link>,
                  //   ],
                  // },
                  // {
                  //   icon: SupportAgent,
                  //   title: "Live Support",
                  //   content: [
                  //     "Urgent? Go to live chat portal",
                  //     <Link
                  //       href="https://www.supportlive.com"
                  //       target="_blank"
                  //       sx={{
                  //         color: "#215d66",
                  //         textDecoration: "none",
                  //         fontWeight: 500,
                  //       }}
                  //     >
                  //       www.supportlive.com
                  //     </Link>,
                  //   ],
                  // },
                  {
                    icon: WhatsApp,
                    title: "Whatsapp",
                    content: [
                      "Call or message us on",
                      <Link
                        href="https://wa.me/+8801886212601"
                        target="_blank"
                        sx={{
                          color: "#215d66",
                          textDecoration: "none",
                          fontWeight: 500,
                        }}
                      >
                        +880 1886 212601
                      </Link>,
                    ],
                  },
                ].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      mb: 4,
                      p: 3,
                      borderRadius: 2,
                      bgcolor: "rgba(25, 118, 210, 0.04)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        bgcolor: "rgba(25, 118, 210, 0.08)",
                        transform: "translateX(8px)",
                      },
                    }}
                  >
                    <item.icon
                      sx={{
                        color: "#215d66",
                        mr: 2,
                        fontSize: 28,
                        mt: 0.5,
                      }}
                    />
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                        {item.title}
                      </Typography>
                      {item.content.map((line, i) => (
                        <Typography
                          key={i}
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 0.5 }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactUs;

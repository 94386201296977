import React, { useState } from "react";
import "./Header.css";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Box,
  Menu,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Divider,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SearchBar from "./SearchBar";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { label: "Anti-Cancer Orals", link: "/about-us" },
    { label: "Anti-Cancer Parenteral", link: "/contact-us" },
    { label: "Biological Products", link: "/how-it-works" },
    { label: "Anti-Viral", link: "/account" },
    { label: "General Products", link: "/login" },
  ];

  const navLinks = [
    { label: "Home", link: "/" },
    { label: "About Us", link: "/about-us" },
    { label: "Our Services", link: "/our-services" },
    { label: "Brand Medicines", link: "/brand-medicines" },
    { label: "How It Works", link: "/how-it-works" },
    { label: "Prescription", link: "/prescriptions" },
    { label: "Contact Us", link: "/contact-us" },
  ];

  const SearchBarWrapper = () => {
    const location = useLocation();
    return location.pathname === "/" ? <SearchBar /> : null;
  };

  const MobileMenu = () => (
    <Drawer
      anchor="right"
      open={mobileMenuOpen}
      onClose={() => setMobileMenuOpen(false)}
    >
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          {navLinks.map((item, index) => (
            <ListItem
              key={index}
              component={Link}
              to={item.link}
              onClick={() => setMobileMenuOpen(false)}
              sx={{
                color: "black",
                textDecoration: "none",
                "&:hover": { bgcolor: "#e8e8e8" },
              }}
            >
              {item.label}
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <>
      {/* Top contact bar */}
      <Box bgcolor="#215d66" color="white" py={1}>
        <Container maxWidth="lg">
          {isMobile ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="body2">
                Email Address: info@sarpharma-international.com;
                export.sarpharma@gmail.com
              </Typography>
              <Box component="span">
                Call Us: +880-1886-212601 (WhatsApp, WeChat & Telegram)
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2">
                Email Address: info@sarpharma-international.com;
                export.sarpharma@gmail.com
              </Typography>
              <Box component="span">
                Call Us: +880-1886-212601 (WhatsApp, WeChat & Telegram)
              </Box>
            </Box>
          )}
        </Container>
      </Box>

      {/* Main header section */}
      <Container maxWidth="lg">
        <Box display="flex" alignItems="start" py={2}>
          {/* Logo */}
          <Box
            flexShrink={0}
            display="flex"
            alignItems="start"
            justifyContent="space-between"
            flexDirection="row"
            gap={2}
          >
            {isMobile ? (
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            <img
              src="/assets/images/logo_main.png"
              alt="SAR Pharma Logo"
              style={{
                width: isMobile ? "50px" : "60px",
                height: isMobile ? "auto" : "80px",
              }}
            />
          </Box>

          {/* Right side content */}

          <Box flex={1} ml={2}>
            {/* Company name and tagline */}
            <Box
              display="flex"
              alignItems={isMobile ? "start" : "center"}
              flexDirection={isMobile ? "column" : "row"}
              mb={2}
            >
              <Typography
                // variant={isMobile ? "h5" : "h3"}
                color="#044458"
                fontWeight="bold"
                sx={{
                  mr: isMobile ? 0 : 2,
                  mb: isMobile ? 1 : 0,
                  fontSize: isMobile ? "20px" : "35px",
                }}
              >
                SAR Pharma International
              </Typography>
              <Typography
                variant={isMobile ? "subtitle1" : "subtitle2"}
                color="error"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                A trusted worldwide medicine supplier
              </Typography>
            </Box>

            {/* Navigation menu */}
            {!isMobile ? (
              <Box
                display="flex"
                gap={3}
                sx={{
                  backgroundColor: "#014b3b",
                  borderRadius: "5px",
                  padding: "10px",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {navLinks.map((item, index) => (
                  <Link
                    key={index}
                    to={item.link}
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    {item.label}
                  </Link>
                ))}
              </Box>
            ) : null}

            {/* Search bar */}
            <SearchBarWrapper />
          </Box>
        </Box>
      </Container>

      <MobileMenu />
    </>
  );
};

export default Header;

import React from "react";
import { Container, Typography, Box, Card, CardContent } from "@mui/material";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

const InternationalBrand = () => {
  return (
    <Box>
      {/* Hero Section with Image Overlay */}
      <Box
        sx={{
          position: "relative",
          height: "300px",
          width: "100%",
          overflow: "hidden",
          mb: 6,
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay
              zIndex: 1,
            },
          }}
        >
          <img
            src="/assets/images/international.jpeg"
            alt="SAR Pharma International Medicines"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        {/* Overlay Content */}
        <Container
          maxWidth="lg"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 2,
          }}
        >
          <MedicalServicesIcon
            sx={{
              fontSize: 80,
              color: "white",
              mb: 3,
            }}
          />
          <Typography
            variant="h1"
            sx={{
              color: "white",
              fontWeight: 800,
              fontSize: { xs: "2.5rem", md: "4rem" },
              textAlign: "center",
              mb: 2,
              textTransform: "uppercase",
              letterSpacing: "0.1em",
              textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
            }}
          >
            International Brand
          </Typography>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* Title Card */}
        <Card
          elevation={0}
          sx={{
            mb: 4,
            borderRadius: 4,
            background: "linear-gradient(135deg, #1976d2, #1565c0)",
            color: "white",
          }}
        >
          <CardContent sx={{ p: 4, backgroundColor: "#215d66" }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                lineHeight: 1.4,
                textAlign: "center",
              }}
            >
              SAR Pharma International is a renowned exporter of internationally
              branded medicines, providing access to a wide range of
              high-quality pharmaceutical products for patients worldwide.
            </Typography>
          </CardContent>
        </Card>

        {/* Description Card */}
        <Card
          elevation={0}
          sx={{
            borderRadius: 4,
            bgcolor: "white",
            boxShadow: "0 4px 24px rgba(0,0,0,0.1)",
          }}
        >
          <CardContent sx={{ p: 6 }}>
            <Box
              sx={{
                mb: 4,
                p: 4,
                bgcolor: "rgba(25, 118, 210, 0.04)",
                borderRadius: 2,
                border: "1px solid rgba(25, 118, 210, 0.1)",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2rem",
                  lineHeight: 1.8,
                  color: "text.primary",
                }}
              >
                Through strong partnerships with reputable manufacturers and
                suppliers, we ensure the authenticity, safety, and efficacy of
                every medication we distribute. Our extensive catalog covers
                various therapeutic categories, including oncology/anti-cancer
                medicine, cardiovascular treatments, neurology medications,
                respiratory care, and more. With a reliable global supply chain,
                we offer a steady availability of internationally recognized
                medicines.
              </Typography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                color: "text.secondary",
                fontStyle: "italic",
                textAlign: "center",
                maxWidth: "900px",
                mx: "auto",
              }}
            >
              Our knowledgeable team assists customers in selecting the most
              suitable options, and we strive to offer cost-effective pricing
              without compromising quality.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default InternationalBrand;

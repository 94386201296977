// // src/components/AboutUs.js

// import React from 'react';
// import { Container, Grid, Typography, Box } from '@mui/material';
// import './AboutUs.css';

// const AboutUs = () => {
//   return (
//     <Container maxWidth="lg" className="about-us">
//       <Box textAlign="center" my={4}>
//         <Typography variant="overline" display="block" gutterBottom>
//           ABOUT US
//         </Typography>
//         <Typography variant="h4" gutterBottom>
//           Educating and empowering the community
//         </Typography>
//       </Box>
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={8}>
//           <Typography variant="h6" gutterBottom>
//             Perspiciatis unde omnis iste natus error inventore.
//           </Typography>
//           <Typography paragraph>
//             Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
//           </Typography>
//           <Typography variant="h6" gutterBottom>
//             Dolor sit amet consectetur adipisicing elit. Unde, quasi.
//           </Typography>
//           <Typography paragraph>
//             Nemo dolores, iusto pariatur corporis quis ullam harum voluptate porro officiis aliquam quas explicabo?
//           </Typography>
//           <Typography paragraph>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias similique, consequuntur incidunt at repellendus ratione totam ex excepturi iste. Similique ipsum numquam recusandae, eligendi maiores temporibus officia harum labore deleniti quasi.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} md={4} className="video-container">
//           <img src="/assets/images/video-placeholder.png" alt="Video Placeholder" className="video-thumbnail" />
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default AboutUs;

import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import MissionIcon from "@mui/icons-material/Flag";
import VisionIcon from "@mui/icons-material/Visibility";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const AboutUs = () => {
  return (
    <Container maxWidth="lg">
      {/* Hero Section */}
      <Box sx={{ position: "relative", height: "300px", width: "100%", mb: 8 }}>
        <img
          src="/assets/images/about_us.jpg"
          alt="About Us"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "white",
              fontWeight: 800,
              textTransform: "uppercase",
              letterSpacing: 2,
              mb: 2,
            }}
          >
            About Us
          </Typography>
          {/* <Typography
            variant="h5"
            sx={{
              color: "rgba(255, 255, 255, 0.9)",
              maxWidth: "800px",
              textAlign: "center",
            }}
          >
            Committed to Excellence in Healthcare
          </Typography> */}
        </Box>
      </Box>

      {/* History Section */}
      <Box sx={{ mb: 10 }}>
        <Typography
          variant="h4"
          sx={{ mb: 4, fontWeight: 700, color: "#215d66" }}
        >
          Our History
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 4,
          }}
        >
          {/* Left side - Cards */}
          <Box sx={{ flex: "0 0 60%" }}>
            {[
              {
                title: "A Humble Beginning",
                content:
                  "In its early days, SAR Pharma primarily focused on sourcing and supplying a diverse range of medicines from Bangladesh. This laid the foundation for a company dedicated to quality, integrity, and patient care.",
              },
              {
                title: "A Response to Global Crisis",
                content:
                  "The world faced an unprecedented challenge with the outbreak of the COVID-19 pandemic. SAR Pharma International swiftly pivoted to meet the urgent global demand for emergency medicines.",
              },
              {
                title: "A Commitment to Oncology",
                content:
                  "Today, SAR Pharma International is deeply committed to the field of oncology medicine. We proudly source and supply a broad spectrum of oncology medications, both from Bangladesh and overseas sources.",
              },
              {
                title: "Our Ongoing Journey",
                content:
                  "As we continue our journey, our focus remains steadfast: to enhance healthcare, support patients in their battle against disease, and contribute to the well-being of communities around the globe.",
              },
            ].map((item, index) => (
              <Card
                key={index}
                sx={{
                  mb: 2,
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  transition: "transform 0.2s ease",
                  "&:hover": {
                    transform: "translateY(-4px)",
                  },
                }}
              >
                <CardContent sx={{ p: 3 }}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      mb: 2,
                      color: "#215d66",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {item.content}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>

          {/* Right side - Image */}
          <Box
            sx={{
              flex: "0 0 40%",
              display: { xs: "none", md: "block" },
              position: "relative",
              height: "300px",
              borderRadius: 2,
              overflow: "hidden",
              boxShadow: "0 4px 24px rgba(0,0,0,0.1)",
            }}
          >
            <img
              src="/assets/images/our_history.jpg"
              alt="Our History"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Who We Are Section */}
      <Box sx={{ mb: 10 }}>
        <Typography
          variant="h4"
          sx={{ mb: 4, fontWeight: 700, color: "#215d66" }}
        >
          Who We Are
        </Typography>
        <Card sx={{ boxShadow: "0 4px 24px rgba(0,0,0,0.1)" }}>
          <CardContent sx={{ p: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" paragraph>
                  We understand the critical importance of providing reliable
                  and high-quality medications to patients around the world. Our
                  dedicated team works tirelessly to ensure that the right
                  medicines reach those who need them, regardless of their
                  geographical location. We pride ourselves on our ability to
                  source and supply internationally branded medicines, catering
                  to the diverse needs of our clients from various parts of the
                  globe.
                </Typography>
                <Typography variant="body1" paragraph>
                  In addition to our expertise in oncology medicine, we also
                  offer a comprehensive range of surgical items, further
                  expanding our product portfolio to meet the demands of
                  healthcare professionals and institutions. By maintaining
                  strong relationships with reputable manufacturers and
                  suppliers, we guarantee the authenticity and efficacy of the
                  products we deliver.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" paragraph>
                  Our commitment to customer satisfaction is unwavering. SAR
                  Pharma International is committed to providing direct access
                  to medicine for patients and customers, ensuring a streamlined
                  and efficient experience. With our exceptional shipping
                  methods, we guarantee swift and secure delivery, minimizing
                  any delays in receiving essential treatments. Our competitive
                  pricing ensures that quality medicine remains accessible and
                  affordable to all, while our secure payment methods provide
                  peace of mind.
                </Typography>
                <Typography variant="body1" paragraph>
                  At SAR Pharma International, we prioritize the well-being and
                  health of individuals worldwide. Through our extensive network
                  and dedication to excellence, we strive to make a positive
                  impact on global healthcare by supplying superior
                  pharmaceutical products and facilitating access to life-saving
                  treatments.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>

      {/* Mission Vision Goals Section */}
      <Box sx={{ mb: 8 }}>
        <Box
          sx={{ position: "relative", height: "300px", width: "100%", mb: 6 }}
        >
          <img
            src="/assets/images/mission_vision.jpg"
            alt="Mission Vision"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Our Mission Vision & Goal
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={4}>
          {[
            {
              icon: MissionIcon,
              title: "Our Mission",
              content:
                "To provide high-quality products that combine performance with value pricing, while establishing successful relationships with our customers and suppliers.",
            },
            {
              icon: VisionIcon,
              title: "Our Vision",
              content:
                "To be the leading provider in our industry, recognized for our customer satisfaction, innovative solutions, and commitment to quality.",
            },
            {
              icon: EmojiEventsIcon,
              title: "Our Goals",
              content:
                "To empower communities through sustainable practices and support initiatives that enhance the overall well-being of our society.",
            },
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  boxShadow: "0 4px 24px rgba(0,0,0,0.1)",
                  transition: "transform 0.2s ease",
                  "&:hover": {
                    transform: "translateY(-4px)",
                  },
                }}
              >
                <CardContent sx={{ p: 4, textAlign: "center" }}>
                  <Box
                    sx={{
                      bgcolor: "#215d66",
                      width: 60,
                      height: 60,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mx: "auto",
                      mb: 3,
                    }}
                  >
                    <item.icon sx={{ color: "white", fontSize: 30 }} />
                  </Box>
                  <Typography
                    variant="h5"
                    sx={{ mb: 2, fontWeight: 600, color: "#215d66" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {item.content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutUs;

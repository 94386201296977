// src/OurServices.js
import React from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Home,
  Flight,
  DirectionsCar,
  HealthAndSafety,
  ArrowForward,
} from "@mui/icons-material";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import MedicationIcon from "@mui/icons-material/Medication";
import FactoryIcon from "@mui/icons-material/Factory";

const services = [
  {
    icon: <MedicationIcon fontSize="large" />,
    title: "Brand Medicine Supply",
    description: "Want to know more",
    link: "/brand-medicine-supply",
  },
  {
    icon: <FactoryIcon fontSize="large" />,
    title: "Contract Manufacturing",
    description: "Want to know more",
    link: "/contract-manufacturing",
  },
  {
    icon: <VaccinesIcon fontSize="large" />,
    title: "Surgical Items <br /> Supply",
    description: "Want to know more",
    link: "/surgical-items-supply",
  },
];

const OurServices = () => {
  const theme = useTheme();

  return (
    <Container sx={{ pb: 5 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h4" align="left" gutterBottom>
          Discover all our Services.
        </Typography>
        {/* <Button
          variant="contained"
          sx={{ bgcolor: "#11A193", "&:hover": { bgcolor: "#11A193" } }}
        >
          View all Services
        </Button> */}
      </Box>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link to={service.link} style={{ textDecoration: "none" }}>
              <Paper
                elevation={3}
                sx={{
                  transition: "transform 0.3s, border-color 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    borderColor: "#11A193",
                    "& .arrowBox": {
                      bgcolor: "#11A193",
                      color: "#fff",
                    },
                  },
                  p: 3,
                  borderRadius: "8px",
                  border: "1px solid transparent",
                  position: "relative",
                }}
              >
                <Box textAlign="center" sx={{ color: "#11A193" }}>
                  {service.icon}
                </Box>
                <Typography variant="h6" align="center" sx={{ mt: 2, mb: 1 }}>
                  {/* {service.title} */}
                  <span dangerouslySetInnerHTML={{ __html: service.title }} />
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                >
                  {service.description}
                </Typography>
                <Box
                  className="arrowBox"
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    bgcolor: "#f5f5f5",
                    p: 1,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#11A193",
                  }}
                >
                  <ArrowForward />
                </Box>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OurServices;

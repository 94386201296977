// src/components/HowItWorks.js

import React from "react";
import {
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const steps = [
  {
    title: "Make An Enquiry",
    description:
      "Use our search bar on the homepage or select medicine from the page.",
    step: 1,
    details: [
      "Use our search bar on the homepage or select medicine from the page.",
      "Once on the medicine page, click on 'Submit an enquiry' to leave us your details.",
      "You can also make enquiry via any of our social media like WhatsApp, LinkedIn, Instagram etc or mail us.",
    ],
  },
  {
    title: "Receive a Quote",
    description: "Get the best possible quote for the medicine you need.",
    step: 2,
    details: [
      "You will receive a quote as per your required quantities.",
      "The cost of shipping like DHL, FedEx or UPS will be added as per the shipping destination.",
    ],
  },
  {
    title: "Order Confirmation",
    description: "Confirm your order after reviewing the quote.",
    step: 3,
    details: [
      "You must confirm the order.",
      "For patient supply, you have to share your prescription with details.",
    ],
  },
  {
    title: "Generate Proforma Invoice",
    description: "An invoice is generated for your order.",
    step: 4,
    details: [
      "We will provide you an invoice with details of price and other cost.",
    ],
  },
  {
    title: "Payment Confirmation",
    description: "Make the payment and receive confirmation.",
    step: 5,
    details: [
      "Make payment of your medication conveniently, through bank or other money transfer process.",
    ],
  },
  {
    title: "Delivery & Shipping",
    description: "Your order is shipped and delivered to your address.",
    step: 6,
    details: [
      "After you have paid for the order, we will process your order.",
      "Our experienced logistics team will collect your medicine and arrange for the safe shipping.",
      "We will keep you informed every step of the way.",
    ],
  },
];

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h2" align="center">
        How it works
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        sx={{ mb: 6 }}
      >
        We help you access your desired medicines with the lowest possible price
      </Typography>

      {/* Desktop Stepper */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stepper
              orientation="horizontal"
              alternativeLabel
              activeStep={2}
              sx={{
                "& .MuiStepConnector-line": {
                  borderColor: "#e0e0e0",
                },
                "& .MuiStepLabel-label": {
                  mt: 1,
                  fontWeight: 600,
                },
                "& .MuiStepIcon-root": {
                  width: "40px",
                  height: "40px",
                  color: "#e0e0e0",
                  "&.Mui-active": {
                    color: "#2196F3",
                  },
                  "&.Mui-completed": {
                    color: "#4CAF50",
                  },
                },
              }}
            >
              {steps.slice(0, 3).map((step, index) => (
                <Step key={index} completed={true}>
                  <StepLabel>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      {step.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ maxWidth: "200px", mx: "auto", mt: 1 }}
                    >
                      {step.description}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Stepper
              orientation="horizontal"
              alternativeLabel
              activeStep={2}
              sx={{
                "& .MuiStepConnector-line": {
                  borderColor: "#e0e0e0",
                },
                "& .MuiStepLabel-label": {
                  mt: 1,
                  fontWeight: 600,
                },
                "& .MuiStepIcon-root": {
                  width: "40px",
                  height: "40px",
                  color: "#e0e0e0",
                  "&.Mui-active": {
                    color: "#2196F3",
                  },
                  "&.Mui-completed": {
                    color: "#4CAF50",
                  },
                },
              }}
            >
              {steps.slice(3, 6).map((step, index) => (
                <Step key={index + 3} completed={true}>
                  <StepLabel>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      {step.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ maxWidth: "200px", mx: "auto", mt: 1 }}
                    >
                      {step.description}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Box>

      {/* Mobile Stepper */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Stepper
          orientation="vertical"
          sx={{
            "& .MuiStepConnector-line": {
              borderColor: "#e0e0e0",
              minHeight: "30px",
            },
          }}
        >
          {steps.map((step, index) => (
            <Step key={index} completed={true}>
              <StepLabel>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {step.title}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  {step.description}
                </Typography> */}
              </StepLabel>
              {/* <StepContent>
                <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.50" }}>
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    {step.details.map((detail, i) => (
                      <li
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: "8px",
                        }}
                      >
                        <CheckCircleIcon
                          sx={{
                            mr: 1,
                            color: "success.main",
                            fontSize: "1rem",
                            mt: 0.5,
                          }}
                        />
                        <Typography variant="body2">{detail}</Typography>
                      </li>
                    ))}
                  </ul>
                </Paper>
              </StepContent> */}
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Detailed Accordion Section */}
      <Box sx={{ mt: 8 }}>
        {steps.map((step, index) => (
          <Accordion
            key={index}
            sx={{
              mb: 2,
              borderRadius: 2,
              "&:before": { display: "none" },
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                borderRadius: 2,
                "&:hover": { bgcolor: "grey.50" },
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                Step {step.step}: {step.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                {step.details.map((detail, i) => (
                  <li
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: "8px",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        mr: 1,
                        color: "success.main",
                        fontSize: "1rem",
                        mt: 0.5,
                      }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {detail}
                    </Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default HowItWorks;

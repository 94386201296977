// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Product from "./pages/Product";
// import Cart from './components/Cart';
// import Checkout from './components/Checkout';
// import Login from './pages/Login';
// import Signup from './pages/Signup';
import "./App.css"; // Import global CSS
import { StoreProvider } from "./context/Store";
import ContactUs from "./components/Contact/ContactUs";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import NotFound from "./components/404/NotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SubHeader from "./components/SubHeader/SubHeader";
import AboutUs from "./components/about/AboutUs";
import MissionVision from "./components/missionvision/MissionVision";
import OurServices from "./components/services/OurServices";
import CompanyHistory from "./components/companyhistory/CompanyHistory";
import BrandMedicineSupply from "./components/services/BrandMedicineSupply";
import ContractManufacturing from "./components/services/ContractManufacturing";
import SurgicalItemsSupply from "./components/services/SurgicalItemsSupply";
import Prescription from "./components/prescriptions/Prescription";
import BrandMedicine from "./components/BrandMedicine/BrandMedicine";
import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  useTheme,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import CategoryPage from "./pages/CategoryPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const App = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCategoryClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCategoryClose = () => {
    setAnchorEl(null);
  };

  const categories = [
    {
      label: "Anti-Cancer Orals",
      link: "/category/anti-cancer-orals",
      icon: "🔴", // Optional: You can add icons if needed
    },
    {
      label: "Anti-Cancer Parenteral",
      link: "/category/anti-cancer-parenteral",
      icon: "🔵",
    },
    {
      label: "Biological Products",
      link: "/category/biological-products",
      icon: "🟢",
    },
    {
      label: "Anti-Viral",
      link: "/category/anti-viral",
      icon: "🟡",
    },
    {
      label: "General Products",
      link: "/category/general-products",
      icon: "⭐",
    },
    {
      label: "Surgical Items",
      link: "/category/surgical-items",
      icon: "🔴",
    },
  ];
  const handleAllCategoryClick = () => {
    window.location.href = "/";
  };

  return (
    <StoreProvider>
      <Router>
        <Header />
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {/* Mobile Category Dropdown */}
            {isMobile && (
              <Grid item xs={12} mb={2}>
                <Button
                  fullWidth
                  onClick={handleCategoryClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    bgcolor: "#014b3b",
                    color: "white",
                    "&:hover": { bgcolor: "#014b3b" },
                    textAlign: "left",
                    justifyContent: "space-between",
                    py: 1.5,
                  }}
                >
                  All Categories
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCategoryClose}
                  sx={{ mt: 1 }}
                >
                  {categories.map((category, index) => (
                    <MenuItem
                      key={index}
                      component={Link}
                      to={category.link}
                      onClick={handleCategoryClose}
                      sx={{ minWidth: "200px" }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        {category.icon}
                      </span>
                      {category.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            )}

            {/* Desktop Category Menu */}
            {!isMobile && (
              <Grid item xs={3}>
                <Box
                  bgcolor="#014b3b"
                  p={2}
                  onClick={handleAllCategoryClick}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="h6" color="white">
                    All Categories
                  </Typography>
                </Box>
                <List component="nav" sx={{ bgcolor: "#215d66" }}>
                  {categories.map((category, index) => (
                    <ListItem
                      key={index}
                      component={Link}
                      to={category.link}
                      sx={{
                        color: "white",
                        borderBottom: "1px solid #e0e0e0",
                        textDecoration: "none",
                        "&:hover": {
                          bgcolor: "#215d66",
                          textDecoration: "none",
                        },
                        display: "flex",
                        alignItems: "center",
                        padding: "12px 16px",
                      }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        {category.icon}
                      </span>
                      {category.label}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {/* Main Content Area */}
            <Grid item xs={isMobile ? 12 : 9}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product/:id" element={<Product />} />
                <Route
                  path="/category/:categoryId"
                  element={<CategoryPage />}
                />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/mission-vision" element={<MissionVision />} />
                <Route path="/company-history" element={<CompanyHistory />} />
                <Route path="/our-services" element={<OurServices />} />
                <Route path="/prescriptions" element={<Prescription />} />
                <Route
                  path="/brand-medicine-supply"
                  element={<BrandMedicineSupply />}
                />
                <Route path="/brand-medicines" element={<BrandMedicine />} />
                <Route
                  path="/contract-manufacturing"
                  element={<ContractManufacturing />}
                />
                <Route
                  path="/surgical-items-supply"
                  element={<SurgicalItemsSupply />}
                />
                <Route path="*" element={<NotFound />} />

                {/* <Route path="/cart" component={Cart} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/login" component={Login} />
                <Route path="/signup" component={Signup} /> */}
              </Routes>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Router>
    </StoreProvider>
  );
};

export default App;

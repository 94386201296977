import React from "react";
import ProductList from "../components/product/ProductList";
import { Container } from "@mui/material";
import OurServices from "../components/services/OurServices";

const Home = () => (
  <Container maxWidth="lg" style={{ padding: "0 0" }}>
    <ProductList />
    <OurServices />
  </Container>
);

export default Home;
